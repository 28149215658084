import { authPost } from './commons';

function genYTActionBody(doc) {
  if (doc.action === 'yt.create.video') {
    return {
      type: doc.action,
      title: doc.title,
      content: doc.content,
      privacy: doc.privacy || 'public',
      attachment: doc.attachment,
    };
  }
  if (doc.action === 'yt.like.video') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      react_type: doc.react_type,
    };
  }
  if (doc.action === 'yt.comment.video') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      content: doc.content,
    };
  }
  if (doc.action === 'yt.subscribe.channel') {
    return {
      type: doc.action,
      channel_id: doc.channel_id,
      react_type: doc.subscribe_type,
    };
  }
  if (doc.action === 'yt.reply.comment') {
    return {
      type: doc.action,
      comment_url: doc.post_url,
      content: doc.content,
    };
  }
  if (doc.action === 'yt.edit.name') {
    return {
      type: doc.action,
      first_name: doc.first_name,
      last_name: doc.last_name,
    };
  }
  if (doc.action === 'yt.update.picture') {
    return {
      type: doc.action,
      picture: doc.picture,
    };
  }

  return {};
}

function genActionBody(doc, excelData) {
  if (doc.action === 'like.post') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      react_type: doc.react_type,
    };
  }
  if (doc.action === 'create.post') {
    let ret = {
      type: doc.action,
      target_type: doc.target_type,
      target_id: doc.target_id,
      content: doc.content,
    };

    if (doc.attachment && doc.attachment !== '') {
      ret['attachment'] = doc.attachment
      ret['attachment_type'] = doc.attachment_type
    }
    return ret
  }
  if (doc.action === 'comment.post' || doc.action === 'reply.post') {
    let ret = {
      type: doc.action,
      post_url: doc.post_url,
      content: doc.content,
    };

    if (doc.attachment && doc.attachment !== '') {
      ret['attachment'] = doc.attachment
      ret['attachment_type'] = doc.attachment_type
    }
    return ret
  }
  if (doc.action === 'share.post') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      share_to: doc.target_url,
      share_type: doc.target_type,
      content: doc.content,
    };
  }
  if (doc.action === 'join.page' || doc.action === 'join.group') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      answer_list: doc.answer_list || [],
    };
  }
  if (doc.action === 'report') {
    return {
      type: doc.action,
      post_url: doc.post_url,
      report_reason: doc.report_reason,
    };
  }
  if (doc.action === 'batch.report') {
    const index = excelData.fields.findIndex(
      (f) => f === doc.report_entity_field,
    );
    const entities = excelData.rows
      .map((row) => row[index])
      .map((url) => ({ url }));

    return {
      type: doc.action,
      entities: entities,
      times: doc.report_times,
    };
  }
  if (doc.action === 'add.friend') {
    return {
      type: doc.action,
      profile_id: doc.target_id,
    }
  }

  return {};
}

function addTrigger(doc, action) {
  doc['trigger'] = action['trigger']

  return doc
}

function add(user_id, flow, actions, excelData) {
  flow.platform = flow.platform || 'facebook';

  const doc = {
    name: flow.name,
    platform: flow.platform,
    trigger: {
      type: flow.trigger,
    },
    accounts: flow.accounts,
    tags: flow.tags,
    actions: actions.map(a => flow.platform === 'youtube' ? genYTActionBody(a) : genActionBody(a, excelData)),
  };

  if (flow.trigger === 'schedule') {
    doc.actions = doc.actions.map((a, i) => addTrigger(a, actions[i]))
  }

  return authPost(`/api/v1/users/${user_id}/flows`, doc);
}

function run(flow_id) {
  return authPost(`/api/v1/flows/${flow_id}/tasks`);
}

export { add, run };
